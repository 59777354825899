@import 'src/global/mixins.scss';

.resultsSections {
  @include container;
  gap: calc(var(--global-space-half) * 8);

  .sectionContainer {
    @include container(calc(var(--global-space-half) * 3));
    max-width: 600px;
  }

  .interactionsSummary {
    margin-bottom: calc(var(--global-space-half) * 3);

    .heading {
      margin-bottom: var(--global-space-single);
    }
  }
}
