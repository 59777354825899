@import 'src/global/mixins.scss';

.badgesList {
  display: flex;
  flex-wrap: wrap;
  gap: var(--global-space-single);
  height: 50px;
}

.badge {
  @include flex-stretch;
}
