@import 'src/global/mixins.scss';

.summarizeTooltipContainer {
  @include container(var(--global-space-single));

  .tooltipHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 600;

    .title {
      @include flex-center;
      gap: var(--global-space-single);
      color: black;

      .sparklesIcon {
        height: 14px;
        width: 14px;
        color: black;
      }

      .text {
        font-size: 14px;
        font-weight: 500;
      }
    }

    .closeIcon {
      width: 14px;
      height: 14px;
      color: var(--global-color-gray-10);
    }
  }

  .tooltipBody {
    @include container(var(--global-space-single));
    @include rounded-large;
    background-color: white;
    color: black;
    padding: calc(3 * var(--global-space-half)) var(--global-space-double);

    .loading {
      display: flex;
      align-items: center;
      min-height: 20px;
    }

    .answer {
      font-size: 14px;
      max-height: 180px;
      overflow: scroll;
      @include fade-in-animation(1s);
    }
  }
}
